import React from "react";
import {
    Button,
    Key,
    ListBox,
    ListBoxItem,
    Popover,
    Select,
    SelectValue,
} from "react-aria-components";
import { t } from "ttag";

import { Context, FooterSocialMediaBlock } from "@reactivated";

import { Clickable } from "../Clickables";
import { RichText } from "../RichText";
import { Svg } from "../Svg";

import styles from "./FooterSocialMedia.module.scss";

export const FooterSocialMedia = (props: { value: FooterSocialMediaBlock }) => {
    const context = React.useContext(Context);

    const onSelectionChange = (url: Key) => {
        if (!url) {
            return;
        }
        window.location.href = url.toString();
    };

    return (
        <div className={styles.mediaInner}>
            <div className={styles.iconContainer}>
                {props.value.title && (
                    <RichText
                        value={props.value.title}
                        className={styles.iconTitle}
                    />
                )}
                <div className={styles.iconBlock}>
                    {props.value.icons?.map((icon, k) => (
                        <Clickable
                            key={k}
                            className={styles.footerIcons}
                            href={icon.link || undefined}
                        >
                            {icon.icon && <Svg name={icon.icon} />}
                        </Clickable>
                    ))}
                </div>
            </div>
            {props.value.show_country_selector && (
                <div className={styles.selectContainer}>
                    <Select
                        className={styles.select}
                        placeholder={t`Countries`}
                        onSelectionChange={onSelectionChange}
                    >
                        <Button className={styles.selectButton}>
                            <SelectValue />
                            <Svg
                                name="caret-right"
                                className={styles.downCaret}
                                aria-hidden="true"
                            />
                        </Button>
                        <Popover className={styles.popover}>
                            <ListBox className={styles.listBox}>
                                {context.countries
                                    .sort((a, b) =>
                                        a.display_name.localeCompare(
                                            b.display_name,
                                        ),
                                    )
                                    .map((country) => (
                                        <ListBoxItem
                                            key={country.url}
                                            id={country.url}
                                            className={styles.listBoxItem}
                                        >
                                            {country.display_name}
                                        </ListBoxItem>
                                    ))}
                            </ListBox>
                        </Popover>
                    </Select>
                </div>
            )}
        </div>
    );
};
