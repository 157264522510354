import React from "react";
import { t } from "ttag";

import { Select, SelectItem } from "../Forms/Select";

export const WhoAreYouSelect = ({ className }: { className?: string }) => {
    const whoYouAre = [
        ["Builder", t`Builder`],
        ["Consumer", t`Consumer`],
        ["Dealer", t`Dealer`],
        ["Designer", t`Designer`],
        ["Press", t`Press`],
        ["Architect", t`Architect`],
        ["Other", t`Other`],
    ] as const satisfies [string, string][];
    return (
        <Select
            label={t`Tell Us Who You Are`}
            name="who_you_are"
            isRequired
            placeholder={t`Select an item`}
            className={className}
        >
            {whoYouAre.map(([value, label]) => (
                /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any */
                <SelectItem key={value} id={value} value={value as any}>
                    {label}
                </SelectItem>
            ))}
        </Select>
    );
};
